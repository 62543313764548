<template>
  <el-card shadow="never">
    <el-form
        :model="ruleForm"
        :rules="rules"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
    >
      <el-form-item label="用户名称" prop="userName">
        <el-input v-model="ruleForm.userName" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="用户昵称" prop="nickName">
        <el-input v-model="ruleForm.nickName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="用户邮箱" prop="userMail">
        <el-input v-model="ruleForm.userMail" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="用户类别" prop="userType">
        <el-select v-model="value" placeholder="请选择" >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户所属客户" prop="customerId">
        <el-select v-model="ruleForm.customerId" placeholder="请选择" >
          <el-option
              v-for="item in usercustomeroptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
            type="primary"
            @click="editUserNickName('ruleForm')"
            style="background: #1d90ff; border: none"
        >提交</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import $ from "jquery";

export default {
  data() {
    return {
       options: [
        {
          
          value: 0,
          label: "撰稿人",
        },
        {
          value: 1,
          label: "运营",
        },
        {
          value: -99,
          label: "管理者",
        },
      ],
     usercustomeroptions:[{id:0,name:"公司员工"}],
      value:'',
      userid:'',
      rules: {
        
        nickName: [
          {required: true, message: "请输入用户昵称", trigger: "blur"},
        ],
        userName: [
          {required: true, message: "请输入用户名称", trigger: "blur"},
        ],
        userMail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { pattern:/^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: "请输入正确的邮箱", trigger: "blur"}

        ],
      },
      ruleForm: {
        nickName: "",
        userName:'',
        userPass:'',
        customerId:'',
      },
    };
  },
  methods: {
    editUserNickName(ruleForm) {
     
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
        "/user/update",
        null,
        {
          nickName: this.ruleForm.nickName,
          id: this.ruleForm.id,
          userType: this.value,
          userName: this.ruleForm.userName,
          userMail: this.ruleForm.userMail,
          customerId:this.ruleForm.customerId,

        },
        (successRes) => {
          console.log(successRes);
          if (successRes.status == 200) {
            this.$message("操作成功");
            this.$router.push({ name: "nameList" });
          } else {
            this.$message(successRes.message);
          }
        },
        (failureRes) => {
          this.$message("操作失败");
        }
      );
          
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    },
    getUserInfoById(id) {
      this.$api.get(
        "/user/getById",
        null,
        { id: id },
        (successRes) => {
          console.log(successRes);
          this.ruleForm = successRes.data;
          this.value = successRes.data.userType;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
     getcustomer() {
      this.$api.get(
        "/customer/list",
        null,
        {
          list: false,
        },
        (successRes) => {
          //this.usercustomeroptions = successRes.data.rows;
         let t = successRes.data.rows.length;
         
         for(let i = 0;i<t;i++){
           this.usercustomeroptions.push(successRes.data.rows[i]);
           console.log(successRes.data.rows[i]);

         }
         

          // this.usercustomeroptions.push();
          console.log(this.usercustomeroptions);
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    init() {
      const userId = this.$route.query.id;
      this.getUserInfoById(userId);
      this.getcustomer();
    },
  },
  mounted() {
    this.init();
  },
};
</script>